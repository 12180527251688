import { configureStore } from "@reduxjs/toolkit";
import businessReducer from "../features/businessSlice";

export const store = configureStore({
  reducer: {
    businessReducer: businessReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
