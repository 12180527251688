import React, { useState } from 'react';

// Definición de tipos para las props
interface ImageComponentProps {
  service: {
    url?: string[];
  };
}

function ImageComponent({ service }: ImageComponentProps) {
  const [loaded, setLoaded] = useState(false);

  if (!service.url?.length) {
    return null;
  }

  return (
    <>
      {!loaded && <div style={styles.skeletonStyle}>Cargando...</div>}
      <img
        src={service.url[0]}
        alt="Product"
        onLoad={() => setLoaded(true)}
        onError={() => setLoaded(false)}
        style={loaded ? styles.imageStyle : { display: 'none' }}
      />
    </>
  );
}

export default ImageComponent;


const styles: { [key: string]: React.CSSProperties } = {
  imageStyle: {
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    objectFit: 'cover'
  },
  skeletonStyle: {
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    backgroundColor: '#2c2c2e',
    animation: 'shimmerDark 2s infinite linear',
    background: 'linear-gradient(to right, #2c2c2e 8%, #3a3a3c 18%, #2c2c2e 33%)',
    backgroundSize: '800px 104px'
  }
}