import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useServices } from "../contexts/ServicesContext"; 

const PagoModule: React.FC = () => {
  const navigate = useNavigate();
  const { total } = useServices();  
  console.log("total desde pagomodule: ", total);
  const location = useLocation();

  const handlePagarClick = () => {
    if (location.pathname === '/prepago') {
      navigate('/pago');
    } else {
      navigate(`/prepago?total=${total}`);
    }
  };
  return (
    <div style={styles.paymentModuleContainer} className="backdrop-filter-blur">
      <div style={styles.paymentModuleWrapper}>
        <div style={styles.totalContainer}>
          <span>TOTAL:</span>
          <span style={styles.ivaIncluido}>(IVA INCLUIDO)</span>
        </div>
        <span style={styles.totalAmount}>{total.toFixed(2)} €</span>
        <button onClick={handlePagarClick} style={styles.pagarBtn}>PAGAR</button>
      </div>
    </div>
  );  
};

export default PagoModule;

const styles: { [key: string]: React.CSSProperties } = {
  paymentModuleContainer: {
    position: "fixed",
    bottom: "10px",
    left: "50%",
    transform: "translate(-50%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: "20px",
    width: "90vw",
    maxWidth: "600px",
    zIndex: 10,
    color: "white",
    fontFamily: "Nunito, sans-serif",
    borderRadius: "20px",
    background: "rgba(30, 30, 30, 0.90)",
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 12px 24px 0px rgba(0, 0, 0, 0.12)",
    backdropFilter: "blur(5px)",
  },
  paymentModuleWrapper: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
    paddingTop: "10px",
    display: "flex",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  totalContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "5px",
  },
  totalAmount: {
    fontWeight: "bold",
    alignItems: "flex-end",
  },
  ivaIncluido: {
    fontSize: "0.8em",
    color: "#bbb",
  },
  pagarBtn: {
    backgroundColor: "#374957",
    color: "white",
    padding: "8px 20px",
    borderRadius: "10px",
    border: "none",
    fontSize: "1rem",
    width: "auto",
    fontWeight: "bold",
    fontFamily: "Nunito, sans-serif",
  },
};