import React, { CSSProperties, useEffect, useState } from "react";
import ConfigModule from "../modules/config-module";
import ChatModule from "../modules/chat-module";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();

  const [lowerBottom] = useState(false);
  const [roomInfo, setRoomInfo] = useState<any>();

  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [telError, setTelError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const roomId = "5f5e5f5e5f5e5f5e5f5e5f5e";

  const handleLogin = () => {
    if (tel === "666777888" && password === "11844999E") {
      navigate("/menu");
    } else {
      setErrorMessage("Credenciales incorrectas");
      setTelError(tel !== "666777888");
      setPasswordError(password !== "11844999E");
    }
  };

  const handleTelKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Si se presiona Enter en el campo de teléfono, enfocamos el campo de contraseña
      document.getElementById("passwordInput")?.focus();
    }
  };

  const handlePasswordKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Si se presiona Enter en el campo de contraseña, ejecutamos handleLogin
      handleLogin();
    }
  };

  useEffect(() => {
    if (roomId) {
      import("../simulatedDb/roomstayTable.json")
        .then((res) => {
          const data = res.default;
          if (data) {
            setRoomInfo(
              data.find((item: { _id: string }) => item._id === roomId)
            );
            console.log(roomInfo);
          } else {
            console.error(
              `No se encontró información para el localId: ${roomId}`
            );
          }
        })
        .catch((error) => console.error("Error al cargar los datos:", error));
    } else {
      console.error("No hay localId aún");
    }
  }, [roomId]);

  return (
    <>
      <ConfigModule showGoBack={true} />
      <div style={styles.menuContainer}>
        <div style={styles.loginContainer} className={`backdrop-filter-blur`}>
          <div>
            <div style={styles.roomInfoContainer}>
              <h1>{roomInfo?.name}</h1>
              <h1>{roomInfo?.number}</h1>
            </div>
            <div style={styles.roomSubtitleContainer}>
              <p>
                Necesitamos verificar tu identidad para permitir la entrada a la
                habitación:
              </p>
            </div>
          </div>
          <div style={styles.inputsContainer}>
            <input
              style={{
                ...styles.inputField,
                borderColor: telError ? "red" : "",
              }}
              type="tel"
              placeholder="Teléfono (prueba: 666777888)"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              onKeyPress={handleTelKeyPress}
            />
            <input
              id="passwordInput"
              style={{
                ...styles.inputField,
                borderColor: passwordError ? "red" : "",
              }}
              type="password"
              placeholder="DNI (prueba: 11844999E)"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handlePasswordKeyPress}
            />
          </div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <button style={styles.loginButton} onClick={handleLogin}>
            Iniciar sesión
          </button>
        </div>
        <ChatModule forceBottom={lowerBottom} autoWrite={false} />
      </div>
    </>
  );
}

export default LoginPage;


const styles: { [key: string]: CSSProperties } = {
    menuContainer: {
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      height: "100vh",
      maxHeight: "100vh",
      overflow: "hidden",
      fontFamily: "Nunito, sans-serif",
    },
    loginContainer: {
      display: "flex",
      padding: "10px 10px 20px 10px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "30px",
      borderRadius: "20px",
      background: "rgba(30, 30, 30, 0.80)",
      maxWidth: "550px",
      width: "85vw",
    },
    roomInfoContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      gap: "12px",
      color: "#F9F9F9",
      fontFamily: "Nunito",
      fontSize: "26px",
      fontWeight: 800,
      textTransform: "uppercase",
    },
    roomSubtitleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      color: "#F9F9F9",
      fontFamily: "Nunito",
      fontSize: "14px",
    },
    inputsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      gap: "10px",
      color: "#0e0e0e",
      fontFamily: "Nunito",
      fontSize: "14px",
    },
    inputField: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "90%",
      maxWidth: "350px",
      padding: "10px 15px",
      borderRadius: "20px",
      backgroundColor: "#000000",
      color: "white",
      fontFamily: "Nunito",
      fontSize: "14px",
    },
    loginButton: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "65%",
      maxWidth: "150px",
      padding: "10px 15px",
      borderRadius: "20px",
      backgroundColor: "#f9f9f9",
      color: "black",
      fontFamily: "NunitoBold",
      fontSize: "14px",
      textTransform: "uppercase",
      border: "2px solid #ccc",
    },
  };