import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfigModule from "../modules/config-module";
import { useDispatch, useSelector } from "react-redux";
import itfbIcon from "../icons/itfb_icon.svg";
import downIcon from "../icons/fi-rr-angle-small-down.svg";

import Spline from "@splinetool/react-spline";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showGoBack = false;

  return (
    <>
      <ConfigModule showGoBack={showGoBack} />
      <div style={styles.bodyContainer}>
        <div style={styles.content}>
          <div style={styles.headerLogo}>
            <img src={itfbIcon} style={styles.logo} />
          </div>
          <div style={styles.textsContainer}>
            <h1 style={styles.H1tagline}>CONSULTORA DE SOFTWARE</h1>
            <h2 style={styles.H2tagline}>
              DESARROLLO DE APPS, WEBS Y SERVIDORES
            </h2>
            <h2 style={styles.tagline}>AUTOMATIZACIÓN DE TAREAS</h2>
          </div>
        </div>
        <div style={styles.splineBackground}>
          <Spline scene="https://prod.spline.design/9DN1ASJviq2HWIg2/scene.splinecode" />
        </div>
      </div>

      <button
        style={{ ...styles.startButton }}
        className="backdrop-filter-blur"
      >
        <img src={downIcon} style={styles.logo} />
      </button>

      <div style={styles.bodyContainer}>
        <div>
          
        </div>
      </div>
    </>
  );
}

export default Home;

const styles: { [key: string]: CSSProperties } = {
  bodyContainer: {
    position: "relative",
    height: "100vh",
    width: "100vw",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  splineBackground: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 0,
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
    zIndex: 2,
  },
  textsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    marginBottom: "4em",
    marginTop: "3em",
  },
  headerLogo: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    maxWidth: "150px",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  H1tagline: {
    fontSize: "2em",
  },
  H2tagline: {
    fontSize: "1em",
  },
  startButton: {
    position: "absolute",
    bottom: "1em",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "1em",
    fontWeight: "bold",
    border: "none",
    borderRadius: "20px",
    outline: "none",
    cursor: "pointer",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontFamily: "Nunito, sans-serif",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "50%",
  },
  icon: {
    width: "15px",
    height: "auto",
  },
};
