import React, { useState } from 'react';
import { useTransition, animated } from '@react-spring/web'

const RoomStayModule: React.FC = () => {
  const data = [
    { date: "1", price: "90" },
    { date: "2", price: "90" },
    { date: "3", price: "100" },
    { date: "4", price: "100" },
  ];

  const address = "Edificio Zenit, C. Pagés del Corro, 90, B, 41010 Sevilla";
  const urlAddress = encodeURIComponent(address);

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(prev => !prev);
  };

  const transitions = useTransition(
    showDetails ? data.map((item, index) => ({ ...item, order: index })) : [],
    {
      from: { opacity: 0, transform: "translateY(20px)" },
      enter: { opacity: 1, transform: "translateY(0px)" },
      leave: { opacity: 0, transform: "translateY(20px)" },
      trail: 50,
      keys: item => item.date,
      unique: true,
      order: 'desc'
    }
  );

  return (
    <div style={{ margin: "auto" }}>
      <div style={styles.generalContainer}>
        <div style={styles.roomContainer}>
          <div style={styles.roomContent}>SUITE</div>
          <div style={styles.roomContent}>3</div>
        </div>
        <div style={styles.dateGeneralContainer}>
          {/* fechas */}
          <div style={styles.dateContainer}>
            <div style={styles.date}>
              <div>INICIO</div>
              <div>PRUEBA</div>
            </div>
            <div style={styles.date}>
              <div>FIN</div>
              <div>PRUEBA</div>
            </div>
          </div>
          {/* total */}
          <div style={styles.totalDaysContainer}>
            <div>4 NOCHES</div>
          </div>
        </div>
        <div style={styles.detailsContainer}>
          <div style={styles.detailsRow} onClick={toggleDetails}>
            <img
              src="/icons/fi-rr-angle-small-right.svg"
              alt="Imagen"
              style={{
                ...styles.imageAngle,
                transform: showDetails ? "rotate(90deg)" : "rotate(0deg)"
              }}
            />
            <div style={styles.text}>TOTAL</div>
            <div style={styles.text}>380€</div>
          </div>
          <div style={styles.detailsCol}>
            {transitions((style, item, t, index) => (
              <animated.div key={item.date} style={{ ...styles.mapItem, ...style }}>
                <div>NOCHE</div>
                <div>{item.date}</div>
                <div>{item.price}€</div>
              </animated.div>
            ))}
          </div>
        </div>
        <a href={`https://www.google.com/maps/search/?api=1&query=${urlAddress}`} target="_blank" style={styles.locationContainer}>
          <img
            src="/icons/fi-rr-map-marker.svg"
            alt="Imagen"
            style={styles.imageMarker}
          />
          <div style={styles.locationText}>
            {address}
          </div>
        </a>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  generalContainer: {
    display: "flex",
    padding: "10px 15px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "20px",
    background: "rgba(30, 30, 30, 0.80)",
    maxWidth: "550px",
    width: "85vw",
  },
  roomContainer: {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
    alignSelf: "stretch",
  },
  roomContent: {
    color: "#F9F9F9",
    fontFamily: "Nunito",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
  },
  dateGeneralContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px",
    alignSelf: "stretch",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignSelf: "flex-start",
  },
  totalDaysContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    flex: 1, // Ocupa el espacio restante
    justifyContent: "center", // Alinea al centro horizontalmente
  },
  date: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignSelf: "stretch",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    alignSelf: "stretch",
  },
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    alignItems: "center",
    cursor: 'pointer'
  },
  detailsCol: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "start",
  },
  imageAngle: {
    background: "white",
    borderRadius: "20px",
    width: "20px",
    height: "20px",
    transition: 'transform 0.1s ease'
  },
  text: {
    color: "#F9F9F9",
    fontFamily: "Nunito",
  },
  locationText: {
    color: "#766C6C",
    fontFamily: "Nunito",
    textDecoration: 'underline'
  },
  mapItem: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
  },
  locationContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    cursor: "pointer"
  },
  imageMarker: {
    width: "20px",
    height: "20px",
  },
};

export default RoomStayModule;
