import React, { CSSProperties, useEffect, useState } from "react";
import ConfigModule from "../modules/config-module";
import ChatModule from '../modules/chat-module';
import MesagesModule from "../modules/mesages-module";


function ChatPage() {

  const showGoBack = true;
  const lowerBottom = false;

  return (
    <>

      <ConfigModule showGoBack={showGoBack} />
      <div style={styles.chatContainer} >

        <MesagesModule />
        <div>
          <ChatModule forceBottom={lowerBottom} />
        </div>
      </div>
    </>
  );
}

export default ChatPage;

const styles: { [key: string]: CSSProperties } = {
  chatContainer: {
    color: "white",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    height: "100vh",
    gap: "20px",
    overflow: 'auto'
  },
}