import React, { createContext, ReactNode, useContext, useState } from 'react';

const defaultState: any = {
  addedServices: {},
  addService: () => {},
  removeService: () => {},
  total: 0,
};

const ServicesContext = createContext<any>(defaultState);

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [addedServices, setAddedServices] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [userID, setUserID] = useState<any | null>(localStorage.getItem('userID') || '-1');


  const addService = (service: any) => {
    setAddedServices((prev:any) => {
      console.log("esto es prev:", prev);
      // Retorna un nuevo array con el servicio añadido, en lugar de usar push
      return [...prev, service];
    });

    setTotal((prev:any)=> {
      const total_price_option = service.options.find((op: any) => op.value).price;
      let total_price_add_option = service?.optionsAdd?.length > 0 ?
        service.optionsAdd.reduce((acumulador: any, op: any) => acumulador + (op.value ? op.price : 0), 0)
        : 0;
      return prev + total_price_option + total_price_add_option;
    });

    console.log("Contenido actual de addedServices después de agregar:", addedServices);  // Realiza un log del estado actual
  };
  const removeService = (serviceId: any) => {
    setAddedServices((prev: any) => {
      const newState = { ...prev };
      if (newState[serviceId]) {
        const totalPrice = newState[serviceId].totalPrice;
        setTotal((prevTotal: any) => prevTotal - totalPrice);
        delete newState[serviceId]; 
      }
      return newState;
    });
  };
  
  return (
    <ServicesContext.Provider value={{ userID,setUserID,messages,setMessages,addedServices, addService, removeService, total }}>
      {children}
    </ServicesContext.Provider>
  );
};
