import React from "react";

interface UserData {
  userId: string;
  userCode: string;
}

interface CodigoUsuarioModuleProps {
  userData: UserData;
}

const CodigoUsuarioModule: React.FC<CodigoUsuarioModuleProps> = ({
  userData,
}) => {
  return (
    <div style={styles.codigoUsuarioContainer} className="backdrop-filter-blur">
      <div style={styles.codigoUsuario}>
        <span style={styles.userCode}>#{userData.userCode}</span>
      </div>
      <button style={styles.shareButton}>
        <img src="./WhatsApp_WhiteLogo.svg"></img>
        COMPARTIR <br /> CUENTA
      </button>
    </div>
  );
};

export default CodigoUsuarioModule;

const styles: { [key: string]: React.CSSProperties } = {
  codigoUsuarioContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(30, 30, 30, 0.80)",
    color: "white",
    padding: "10px",
    borderRadius: "20px",
    maxWidth: "550px",
    width: "85vw",
  },
  userCode: {
    fontFamily: "NunitoBold",
    fontSize: "24px",
    marginLeft: "10px",
  },
  shareButton: {
    display:"flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    gap:"10px",
    backgroundColor: "white",
    color: "#374957",
    border: "none",
    padding: "6px 12px",
    borderRadius: "10px",
    cursor: "pointer",
    marginLeft: "auto",
    maxWidth: "50%",
    fontSize: "12px",
    fontFamily: "NunitoBold"
  }
};
