import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

interface BizumModuleProps {
  isEnabled: boolean;
}

const BizumModule: React.FC<BizumModuleProps> = ({ isEnabled }) => {
  const navigate = useNavigate();
  const [BizumIconPath, setBizumIconPath] = useState('');


  const handleButtonClick = () => {
    if (!isEnabled) {
      console.log("Checkbox no marcado, acción bloqueada.");
      return; // Previene la acción si el checkbox no está marcado
    }
    // Procede con la acción de navegación si el checkbox está marcado
    navigate("/ruta-de-destino");
  };

  useEffect(() => {
    const loadPaymentIcons = async () => {
      try {
        const iconsConfig = await import('../simulatedDb/paymentIconsDb.json');
        setBizumIconPath(iconsConfig.bizum);
      } catch (error) {
        console.error("Error al cargar el archivo de configuración de iconos de pago:", error);
      }
    };

    loadPaymentIcons();
  }, []);


    return (
        <button onClick={handleButtonClick} style={styles.button}>
         {BizumIconPath && <img src={BizumIconPath} style={styles.icon} alt="Bizum" />}
        </button>

    );
  };

export default BizumModule;

const styles = {
  button: {
    display: 'flex', 
    justifyContent: 'center',
    alignItems: "center",
    padding: "12px 20px",
    borderRadius: "8px",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#FFF",
    border: "none",
    cursor: "pointer",
    width: "100%",
    fontSize: "16px",
    fontFamily: "NunitoBold",
    color: "black"
  },
  icon: {
    maxWidth: '100%', // Asegura que la imagen no sobrepase el ancho del botón
    maxHeight: '100%'
  },
  checkmark: {
  },
};