import React, { useState, useContext, useEffect } from "react";
import { ServicesModuleProps } from "../simulatedDb/interfaces";
import { useServices } from "../contexts/ServicesContext";
import ImageComponent from "../components/ImageComponent";


const ServicesModule: React.FC<any> = ({ localData, serviceData }) => {

  const { addedServices, addService, removeService, total } = useServices();

  const [services, setServices] = useState<any>([]);
  const [families, setFamilies] = useState<any>([]);
  const [selectedAddOptions, setSelectedAddOptions] = useState<any>({});
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<any>({});
  const [expandedServiceId, setExpandedServiceId] = useState<any>("");
  const [startDates, setStartDates] = useState<any>({});
  const [currentCategory, setCurrentCategory] = useState<any>("");
  const [currentFamily, setCurrentFamily] = useState<any>("");
  const [currentFamilyCategories, setCurrentFamilyCategories] = useState<any>(
    []
  );
  const [filteredServices, setFilteredServices] = useState<any>([]);



  const processServices = (services: any) => {
    const serviceIDs = services.map((service: any) => service._id);
    const tempFilteredServices = serviceData.filter((service: any) =>
      serviceIDs.includes(service._id)
    );
    setFilteredServices(tempFilteredServices);
  };
  useEffect(() => {
    const familiesData = localData.families;
    const familyNames = familiesData.map(
      (family: { name: any }) => family.name
    );
    setFamilies(familyNames);

    if (familiesData[0].categories) {
      const tempServices = familiesData[0].categories[0].services;
      setServices(tempServices);
      processServices(tempServices);
    } else {
      const tempServices = familiesData[0].services;
      setServices(tempServices);
      processServices(tempServices);
    }
  }, []);

  const handleDateChange = (
    serviceId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStartDate = e.target.value;
    setStartDates((prevDates: any) => ({
      ...prevDates,
      [serviceId]: newStartDate,
    }));
  };

  const toggleServiceDescription = (serviceId: string) => {
    setExpandedServiceId(expandedServiceId === serviceId ? "" : serviceId);
  };

  const handleAddOptionChange = (serviceId: string, addOptionIndex: number) => {
    const currentService = services.find(
      (s: { _id: string }) => s._id === serviceId
    );
    if (!currentService || !currentService.optionsAdd) return;

    setSelectedAddOptions((prev: any) => ({
      ...prev,
      [serviceId]: addOptionIndex,
    }));
  };

  const calculateTotalPrice = (serviceId: string) => {
    const service = services.find((s: { _id: string }) => s._id === serviceId);
    if (!service) return 0;
    const optionIndex = selectedOptionIndex[serviceId];
    const basePrice = service.options[optionIndex]?.price ?? 0;

    const addOptionIndex = selectedAddOptions[serviceId];
    const addPrice =
      addOptionIndex !== undefined && service.optionsAdd
        ? service.optionsAdd[addOptionIndex]?.price ?? 0
        : 0;

    return basePrice + addPrice;
  };

  const handleAddToTotal = (serviceId: any, optionN: any, basePrice: any) => {

    if (!serviceId) {
      console.error("No valid service ID provided");
      return;
    }

    if (selectedOptionIndex[serviceId] === undefined) {
      console.error("No option selected for service", serviceId);
      return;
    }
    console.log("Service ID:", serviceId); // Debug: Service ID
    console.log("Base Price:", basePrice); // Debug: Base Price
    console.log("filteredservices:", filteredServices); // Debug: Base Price
    console.log("services:", services); // Debug: Base Price

    if (!serviceId) {
      console.error("No valid service ID provided");
      return;
    }

    const currentService = services.find((s: { _id: string }) => {
      console.log("Checking service:", s); // Imprime cada objeto 's' en el array 'services'
      console.log("Service ID being checked against:", serviceId); // Muestra el ID del servicio que estás buscando

      if (s._id === serviceId) {
        console.log("Match found for service ID:", serviceId, "with service:", s); // Muestra un mensaje si los IDs coinciden
        return true;
      }
      return false;
    });
    if (!currentService) {
      console.error("Service not found:", serviceId);
      return;
    }

    console.log("Current Service:", currentService); // Debug: Current Service

  };

  const handleSectionClick = (familyName: string) => {
    const family = localData.families.find((f: any) => f.name === familyName);

    if (family.categories) {
      const tempCurrentFamilyCategories = family.categories.map(
        (category: any) => category.name
      );
      setCurrentFamilyCategories(tempCurrentFamilyCategories);
      const tempCategoriesServices = family.categories;
      const tempServices = tempCategoriesServices[0].services;
      setServices(tempServices);
      processServices(tempServices);
    } else {
      setCurrentFamilyCategories([]);
      const tempServices = family.services;
      setServices(tempServices);
      processServices(tempServices);
    }

    setCurrentFamily(familyName);
  };

  const handleSelectionCategorie = (
    familyName: string,
    categorieName: string
  ) => {
    const family = localData.families.find((f: any) => f.name === familyName);

    const tempCurrentFamilyCategories = family.categories.map(
      (category: any) => category.name
    );
    setCurrentFamilyCategories(tempCurrentFamilyCategories);
    const tempCategorie = family.categories.find(
      (f: any) => f.name === categorieName
    );
    const tempServices = tempCategorie.services;
    setCurrentCategory(categorieName);
    processServices(tempServices);
  };

  const handleOptionCheckboxChange = (event: any, serviceId: string, option: any) => {
    setFilteredServices((prev: any) => {
      const tempServices = [...prev];
      const index = tempServices.findIndex((serv: any) => serv._id === serviceId);
      for (let i = 0; tempServices[index].options.length > i; i++) {
        tempServices[index].options[i].value = (option.description === tempServices[index].options[i].description && event.target.checked);
      }
      return tempServices;
    });
  }

  const handleOptionAddCheckboxChange = (event: any, serviceId: string, option: any) => {
    setFilteredServices((prev: any) => {
      const tempServices = [...prev];
      const index = tempServices.findIndex((serv: any) => serv._id === serviceId);
      for (let i = 0; tempServices[index].optionsAdd.length > i; i++) {
        if (tempServices[index].optionsAdd[i].description === option.description) {
          tempServices[index].optionsAdd[i].value = event.target.checked;
        }
      }
      return tempServices;
    });
  }

  const addServiceToCart = (service: any) => {
    if (service?.options?.find((option: any) => option.value)) {
      addService(service);
    }

  }

  return (
    <div style={styles.servicesModule}>
      <div style={styles.navigationBar}>
        {currentFamilyCategories.length > 0 ? (
          <>
            <button
              style={styles.navigationBarBackButton}
              onClick={() => {
                setCurrentCategory("");
                setCurrentFamily("");
                setCurrentFamilyCategories([]);
              }}
            >
              Volver
            </button>

            {currentFamilyCategories.map((category: any) => (
              <button
                key={category}
                style={{
                  ...styles.navigationBarButton,
                  ...(category === currentCategory &&
                    styles.navigationBarButtonActive),
                }}
                onClick={() =>
                  handleSelectionCategorie(currentFamily, category)
                }
              >
                {category}
              </button>
            ))}
          </>
        ) : (
          families.map((family: any) => (
            <button
              key={family}
              style={{
                ...styles.navigationBarButton,
                ...(family === currentFamily &&
                  styles.navigationBarButtonActive),
              }}
              onClick={() => handleSectionClick(family)}
            >
              {family}
            </button>
          ))
        )}
      </div>
      <div style={styles.serviceList}>
        {filteredServices.length > 0 ? (
          filteredServices.map((service: any) => (
            <div key={service._id} style={styles.serviceItem}>
              <div style={styles.serviceHeaderContainer}>
                <div style={styles.serviceDataContainer}>
                  <h3 style={styles.serviceItemH3}>{service.name}</h3>
                  {service.paymentType === "MONTHLY" && (
                    <div style={styles.serviceHeader}>
                      <span style={styles.inicioLabel}>INICIO</span>
                      <input
                        type="date"
                        style={styles.serviceStartDate}
                        value={startDates[service._id]}
                        onChange={(e) => handleDateChange(service._id, e)}
                        onBlur={(e) => handleDateChange(service._id, e)}
                      />
                    </div>
                  )}
                  {expandedServiceId === service._id ? (
                    <p style={styles.serviceDescriptionExpanded}>{service.description}</p>
                  ) : (
                    <p style={styles.serviceDescription}>{service.description}</p>
                  )
                  }
                </div>
                <ImageComponent service={service} />
              </div>

              {/*&& expandedServiceId === service._id*/}
              {service?.options?.length > 0 ? service.options.map((option: any) => {
                return <div style={styles.serviceOptionContainer}>
                  <span>{option.description}</span>
                  <div style={styles.serviceOptionColumn}>
                    <span>{option.price}€</span>
                    <input
                      style={styles.serviceOptionCheckbox}
                      type="checkbox"
                      checked={option.value ? option.value : false}
                      onChange={(event: any) => handleOptionCheckboxChange(event, service._id, option)}
                    />
                  </div>
                </div>;
              }) : null
              }
              {service?.optionsAdd?.length > 0 ? service.optionsAdd.map((option: any) => {
                return <div style={styles.serviceOptionContainer}>
                  <span>{option.description}</span>
                  <div style={styles.serviceOptionColumn}>
                    <span>+</span>
                    <span>{option.price}€</span>
                    <input
                      style={styles.serviceOptionCheckbox}
                      type="checkbox"
                      checked={option.value ? option.value : false}
                      onChange={(event: any) => handleOptionAddCheckboxChange(event, service._id, option)}
                    />
                  </div>
                </div>;
              }) : null
              }
              <button onClick={() => addServiceToCart(service)}>AÑADIR</button>
            </div>
          ))
        ) : (
          <div className="no-classes-message">
            No hay ningún servicio en este momento.
          </div>
        )}
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  servicesModule: {
    fontFamily: "Nunito, sans-serif",
    color: "white",
    margin: "auto",
    flex: "auto",
    flexDirection: "column",
    marginBottom: "100px"
  },
  navigationBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "10px 5px 26px 5px",
    maxWidth: "550px",
    width: "85vw",
    gap: "20px",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
  },
  navigationBarBackButton: {
    backgroundColor: "white",
    border: "none",
    color: "black",
    padding: "4px 8px",
    fontSize: "16px",
    borderRadius: "16px",
    position: "sticky",
    left: "0",
    zIndex: "2",
  },
  navigationBarButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    padding: "4px 8px",
    fontSize: "16px",
    borderRadius: "16px",
    whiteSpace: "nowrap",
    transition: "background-color 0.3s, transform 0.3s",
  },
  navigationBarButtonActive: {
    backgroundColor: "#444",
    transform: "scale(1.05)",
  },
  serviceList: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "550px",
    width: "85vw",
    gap: "20px",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  serviceItem: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "550px",
    width: "85vw",
    gap: "10px",
    padding: "16px",
    borderRadius: "20px",
    background: "rgba(30, 30, 30, 0.9)",
    boxShadow:
      "0px 12px 24px 0px rgba(45, 53, 60, 0.12), 0px 0px 2px 0px rgba(45, 53, 60, 0.2)",
    overflow: "hidden",
  },
  serviceHeaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '20px',
    alignSelf: 'stretch'
  },
  serviceDataContainer: {
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '10px',
    flex: '1 0 0'
  },
  serviceHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    alignSelf: 'stretch'
  },
  serviceDescription: {
    overflow: 'hidden',
    color: '#F9F9F9',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 200,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  serviceDescriptionExpanded: {
    color: '#F9F9F9',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 200,
    whiteSpace: 'normal'
  },
  inicioLabel: {
    fontSize: "1em",
    color: "white",
  },
  serviceStartDate: {
    backgroundColor: "#6c6c6c",
    fontFamily: "nunito-sans, sans-serif",
    color: "#f9f9f9",
    padding: "2px 10px",
    borderRadius: "5px",
    fontSize: "14px",
  },
  serviceItemH3: {
    margin: "0 0 8px 0",
    fontSize: "20px",
  },
  serviceItemP: {
    margin: "4px 0",
    fontSize: "14px",
  },
  price: {
    fontWeight: "bold",
    marginTop: "8px",
  },
  serviceCollapsedInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  serviceOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  serviceOption: {
    padding: "0px",
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  priceView: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  originalPrice: {
    textDecoration: "line-through",
    color: "#777",
    fontSize: "1em",
  },
  currentPrice: {
    color: "white",
    fontSize: "1em",
  },
  paymentFrequency: {
    color: "white",
    fontSize: "1em",
  },
  addButton: {
    backgroundColor: "#888",
    color: "white",
    border: "none",
    padding: "5px 0",
    cursor: "pointer",
    width: "25%",
    borderRadius: "8px",
  },
  addContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  selectedOptionInfo: {
    display: "flex",
    alignItems: "center",
    marginLeft: "60%",
    marginBottom: "8px",
  },
  addButton2: {
    backgroundColor: "#888",
    color: "white",
    border: "none",
    padding: "5px 0",
    cursor: "pointer",
    width: "25%",
    borderRadius: "8px",
    marginTop: "2%",
  },
  currentPrice2: {
    color: "white",
    fontSize: "1em",
    marginRight: "10%",
    marginTop: "10px",
  },
  paymentFrequency2: {
    color: "white",
    fontSize: "1em",
    marginTop: "10px",
  },
  priceView2: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "flex-end",
  },
  addButtonAdded: {
    backgroundColor: "#0e0f0e !important",
  },
  serviceOptionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  serviceOptionColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "center"
  }
};

export default ServicesModule;
