import React from "react";
import { CSSProperties } from "react";

interface PaymentFormModuleProps {
  userName: string;
  onCheckboxChange: (isChecked: boolean) => void; // Añade esta línea
}

const PaymentOptionsModule: React.FC<PaymentFormModuleProps> = ({
  userName,
  onCheckboxChange, // Añade esta línea
}) => {

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(e.target.checked);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        HOLA {userName.toUpperCase()}, DEJA TU PEDIDO LISTO
      </div>
      <input
        type="email"
        placeholder="Deja tu email aquí si quieres una copia"
        style={styles.input}
      />
      <div style={styles.checkboxContainer}>
        <label style={styles.checkboxLabel}>
          <input type="checkbox" onChange={handleCheckboxChange} /> Acepto la política de privacidad de IBERIAN
          PAYMENT PARTNERS SL descrita en:
          <a href="https://ibero.app/privacy/" style={styles.link}>
            {" "}
            https://ibero.app/privacy/
          </a>
        </label>
        <label style={styles.checkboxLabel}>
          <input type="checkbox" /> Deseo guardar mi información para futuras
          compras. Estos datos no se usan con fines comerciales ni se venden a
          terceros.
        </label>
        <label style={styles.checkboxLabel}>
          <input type="checkbox" /> Acepto recibir promociones por correo y
          notificaciones. Puede darse de baja en cualquier momento o corregir
          sus datos en info@ibero.app
        </label>
      </div>
    </div>
  );
};

export default PaymentOptionsModule;

const styles: Record<string, CSSProperties> = {
  container: {
    display: "flex",
    padding: "10px 20px",
    flexDirection: "column",
    alignContent: "center",
    gap: "20px",
    borderRadius: "20px",
    background: "#1E1E1E",
    flex: 1,
    maxWidth: "550px",
    width: "85vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  header: {
    color: "white",
    fontFamily: "NunitoBold, sans-serif",
    fontSize: "14px",
    lineHeight: "normal",
    alignSelf: "stretch",
  },
  checkboxLabel: {
    color: "#FAFAFA",
    fontFamily: "Nunito, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "normal",
    flex: "1 0 auto",
  },
  input: {
    display: "flex",
    padding: "10px",
    alignItems: "flex-start",
    gap: "10px",
    alignSelf: "stretch",
    borderRadius: "20px",
    background: "#060606",
    color: "#FAFAFA",
    fontSize: "12px"
  },
  checkboxContainer: {
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  link: {
    textDecoration: "none",
    color: "#007bff",
  },
  button: {
    display: "block",
    width: "100%",
    padding: "15px",
    textAlign: "center" as const,
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: "bold",
    margin: "10px 0",
    cursor: "pointer",
    border: "none",
  },
};
