import React, { useState } from "react";

interface StarRatingProps {
  rating: number;
  onRatingChange: (rating: number) => void;
}

const starBlank = "/assets/SVG/star-blank.svg";
const starFilled = "/assets/SVG/star-filled.svg";

const StarRating: React.FC<StarRatingProps> = ({ rating, onRatingChange }) => {
  const [hoveredRating, setHoveredRating] = useState<number | null>(null);

  const handleMouseEnter = (rating: number) => {
    setHoveredRating(rating);
  };

  const handleMouseLeave = () => {
    setHoveredRating(null);
  };

  const handleRatingClick = (rating: number) => {
    onRatingChange(rating);
  };

  const starStyles: React.CSSProperties = {
    fontSize: "30px",
    fontFamily: "Nunito",
    color: "white",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center" 
  };

  const starContainer: React.CSSProperties = {
    display: "flex",
    gap: "10px",
    marginTop: "5px",
    alignSelf: "center",
  };

  const filledStarStyles: React.CSSProperties = {
    color: "gold", // Cambiar a dorado claro cuando la estrella esté rellenada
  };

  const hoveredStarStyles: React.CSSProperties = {
    color: "gold", // Cambiar a dorado claro cuando se pasa el cursor sobre ellas
  };

  return (
    <div className="star-rating" style={starContainer}>
      {[1, 2, 3, 4, 5].map((star) => (
        <img
          src={rating >= star ? starFilled : starBlank}
          key={star}
          onClick={() => handleRatingClick(star)}
          onMouseEnter={() => handleMouseEnter(star)}
          onMouseLeave={handleMouseLeave}
          style={
            rating >= star
              ? { ...starStyles, ...filledStarStyles }
              : hoveredRating && hoveredRating >= star
              ? { ...starStyles, ...hoveredStarStyles }
              : starStyles
          }
          alt={`Star ${star}`}
        />
      ))}
    </div>
  );
};

export default StarRating;
