import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IndexPage from "./pages/index";
import { ServicesProvider } from "./contexts/ServicesContext";
import MenuPage from "./pages/menu";
import PrepagoPage from "./pages/prepago";
import PagoPage from "./pages/pago";
import ChatPage from "./pages/chat";
import OK from "./pages/ok";
import Home from "./pages/home";
import LoginPage from "./pages/login"
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "./App.css";

const globalCSS = `
  body {
    user-select: none;
    -webkit-user-select: none;
  }
  @viewport {
    width: device-width;
    zoom: 1.0;
    user-zoom: fixed;
  }
`;

function App() {
  useEffect(() => {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0";
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <Provider store={store}>
      <ServicesProvider>
        <style>{globalCSS}</style>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:localId" element={<IndexPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/prepago" element={<PrepagoPage />} />
            <Route path="/pago" element={<PagoPage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/ok" element={<OK />} />
          </Routes>
        </Router>
      </ServicesProvider>
    </Provider>
  );
}

export default App;
