import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfigModule from "../modules/config-module";
import { useDispatch, useSelector } from "react-redux";

import { setInfo } from "../features/businessSlice";

function IndexPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { localId } = useParams();
  const [businessData, setBusinessData] = useState<any>();
  const showGoBack = false;

  useEffect(() => {
    localId && dispatch(setInfo({ localId }));
    localId && sessionStorage.setItem("localId", localId);
  }, [localId, dispatch]);

  useEffect(() => {
    if (localId) {
      import("../simulatedDb/businessTable.json")
        .then((res) => {
          const data = res.default;
          if (data) {
            setBusinessData(
              data.find((item: { _id: string }) => item._id === localId)
            );
            console.log(businessData);
          } else {
            console.error(
              `No se encontró información para el localId: ${localId}`
            );
          }
        })
        .catch((error) => console.error("Error al cargar los datos:", error));
    } else {
      console.error("No hay localId aún");
    }
  }, [localId]);

  const handleStartClick = () => {
    if (businessData && businessData.loginNeeded!== undefined) {
      if (businessData.loginNeeded) {
        navigate("/login");
      } else {
        navigate("/menu");
      }
    }
  };

  return (
    <>
      <ConfigModule showGoBack={showGoBack} />
      <div style={styles.body}>
        <video autoPlay loop muted playsInline style={styles.videoBackground}>
          {businessData?.backgroundVideoUrl ? (
            <>
              <source src={businessData.backgroundVideoUrl} type="video/mp4" />
              <source src={businessData.backgroundVideoUrl} type="video/webm" />
              <source src={businessData.backgroundVideoUrl} type="video/ogg" />
              <source src={businessData.backgroundVideoUrl} type="video/avi" />
              <source src={businessData.backgroundVideoUrl} type="video/mov" />
              <source src={businessData.backgroundVideoUrl} type="video/flv" />
            </>
          ) : null}
        </video>
      </div>
      <div style={styles.indexContainer}>
        <div style={styles.content}>
          <div style={styles.headerLogo}>
            {businessData?.logoUrl ? (
              <img src={businessData.logoUrl} style={styles.logo} />
            ) : null}
          </div>
          {businessData?.slogan ? (
            <p style={styles.tagline}>{businessData.slogan}</p>
          ) : null}
          <button
            style={{ ...styles.startButton }}
            className="backdrop-filter-blur"
            onClick={handleStartClick}
          >
            COMENZAR
          </button>
        </div>
      </div>
    </>
  );
}

export default IndexPage;

const styles: { [key: string]: CSSProperties } = {
  indexContainer: {
    position: "relative",
    height: "100vh",
    maxHeight: "100vh",
    overflow: "hidden",
    fontFamily: "Nunito, sans-serif",
  },
  videoBackground: {
    position: "absolute",
    width: "100%",
    left: "50%",
    top: "50%",
    height: "100%",
    objectFit: "cover",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
  },
  headerLogo: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    maxWidth: "200px",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tagline: {
    fontSize: "1em",
    marginBottom: "4em",
  },
  startButton: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "1em",
    fontWeight: "bold",
    border: "none",
    borderRadius: "20px",
    outline: "none",
    cursor: "pointer",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontFamily: "Nunito, sans-serif",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "50%",
  },
  icon: {
    width: "15px",
    height: "auto",
  },
};
