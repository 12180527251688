import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import StarRating from "../features/starRating";
import { API } from "../ApiURls";
import { useTranslation } from "react-i18next";

const whatsapp = "/assets/SVG/whatsapp.svg";
const google = "/assets/SVG/googleLogo.svg";
const googleLogoBig = "/assets/SVG/google-big-logo.svg";

const OK: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [payed, setPayed] = useState(false);
  const [rating, setRating] = useState<number>(0);
  const handleRetry = () => {
    navigate("/payment");
  };
  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
    if (newRating <= 3) {
    } else if (newRating >= 4) {
    }
  };
  const openGoogleMapsReview = () => {
    if (rating >= 4) {
      const googleMapsReviewURL = `https://www.google.com/maps/search/?api=1/reviews`;
      window.open(googleMapsReviewURL, "_blank");
    }
  };
  const [message, setMessage] = useState<string>("");
  const [orderId, setOrderId] = useState<string>("PRUEBA");

  useEffect(() => {
    const handleURLParams = () => {
      const searchParams = new URLSearchParams(location.search);
      const merchantParameters = searchParams.get("Ds_MerchantParameters");

      console.log(merchantParameters)

      if (merchantParameters) {
        const decodedParams = atob(merchantParameters);
        const parsedParams = JSON.parse(decodedParams);
        const respuesta = parseInt(parsedParams.Ds_Response, 10);
        const orderId = parsedParams.Ds_Order;
        setOrderId(orderId);
        if (respuesta >= 0 && respuesta <= 99) {
          const url = API + "/tickets/getTicketByOrderId?orderId=" + orderId;
          fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => response.json());
          setMessage(t("thanks.authoriced-transaction"));
          setPayed(true);
        } else {
          setPayed(false);
          sessionStorage.removeItem("response1");
          sessionStorage.setItem("codigo", orderId);
          switch (respuesta) {
            case 900:
              setMessage(t("thanks.error-responses.900"));
              break;
            case 400:
              setMessage(t("thanks.error-responses.400"));
              break;
            case 101:
              setMessage(t("thanks.error-responses.101"));
              break;
            case 102:
              setMessage(t("thanks.error-responses.102"));
              break;
            case 106:
              setMessage(t("thanks.error-responses.106"));
              break;
            case 125:
              setMessage(t("thanks.error-responses.125"));
              break;
            case 129:
              setMessage(t("thanks.error-responses.129"));
              break;
            case 172:
              setMessage(t("thanks.error-responses.172"));
              break;
            case 173:
              setMessage(t("thanks.error-responses.173"));
              break;
            case 174:
              setMessage(t("thanks.error-responses.174"));
              break;
            case 180:
              setMessage(t("thanks.error-responses.180"));
              break;
            case 184:
              setMessage(t("thanks.error-responses.184"));
              break;
            case 190:
              setMessage(t("thanks.error-responses.190"));
              break;
            case 191:
              setMessage(t("thanks.error-responses.191"));
              break;
            case 195:
              setMessage(t("thanks.error-responses.195"));
              break;
            case 202:
              setMessage(t("thanks.error-responses.202"));
              break;
            case 904:
              setMessage(t("thanks.error-responses.904"));
              break;
            case 909:
              setMessage(t("thanks.error-responses.909"));
              break;
            case 913:
              setMessage(t("thanks.error-responses.913"));
              break;
            case 944:
              setMessage(t("thanks.error-responses.944"));
              break;
            case 950:
              setMessage(t("thanks.error-responses.950"));
              break;
            case 912:
              setMessage(t("thanks.error-responses.912"));
              break;
            case 9064:
              setMessage(t("thanks.error-responses.9064"));
              break;
            case 9078:
              setMessage(t("thanks.error-responses.9078"));
              break;
            case 9093:
              setMessage(t("thanks.error-responses.9093"));
              break;
            case 9094:
              setMessage(t("thanks.error-responses.9094"));
              break;
            case 9104:
              setMessage(t("thanks.error-responses.9104"));
              break;
            case 9218:
              setMessage(t("thanks.error-responses.9218"));
              break;
            case 9253:
              setMessage(t("thanks.error-responses.9253"));
              break;
            case 9256:
              setMessage(t("thanks.error-responses.9256"));
              break;
            case 9257:
              setMessage(t("thanks.error-responses.9257"));
              break;
            case 9261:
              setMessage(t("thanks.error-responses.9261"));
              break;
            case 9915:
              setMessage(t("thanks.error-responses.9915"));
              break;
            case 9997:
              setMessage(t("thanks.error-responses.9997"));
              break;
            case 9998:
              setMessage(t("thanks.error-responses.9998"));
              break;
            case 9999:
              setMessage(t("thanks.error-responses.9999"));
              break;
            default:
              setMessage(t("thanks.error-responses.default"));
              break;
          }
        }
      }
    };
    handleURLParams();

  }, [location, t]);

  const styles: { [key: string]: React.CSSProperties } = {
    appscreen: {
      backgroundColor: payed ? "#16BF4F" : "#DD2D2D",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      paddingTop: "3em",
      alignItems: "center",
    },
    languajecontainer: {
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: "1",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "#FFFFFF",
    },
    title: {
      fontSize: "32px",
      fontFamily: "NunitoBold",
      textAlign: "center",
      flex: 1,
    },
    message: {
      paddingTop: "1em",
      fontSize: "18px",
      fontFamily: "NunitoLight",
      textAlign: "center",
    },
    footMessage: {
      fontFamily: "NunitoLight",
      fontSize: "13px",
      marginTop: "2em",
      width: "200px",
      textAlign: "center",
    },
    orderId: {
      fontSize: "18px",
      fontFamily: "NunitoBold",
      textAlign: "center",
      marginBottom: "1em",
    },
    retryMessage: {
      fontSize: "20px",
      fontFamily: "NunitoBold",
      textAlign: "center",
      paddingLeft: "1em",
      paddingRight: "1em",
      marginTop: "20px",
    },
    feedBackContainer: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      gap: "20px",
      alignItems: "center",
      width: "290px",
      height: "134px",
      borderRadius: "60px",
      padding: "15px 25px",
    },
    feedBack: {
      display: "flex",
      flexDirection: "column",
      color: "#374957",
      fontSize: "16px",
    },
    feedBackText1: {
      fontFamily: "NunitoLight",
    },
    feedBackText2: {
      color: "#374957",
      fontFamily: "NunitoBold",
    },
    retryButton: {
      backgroundColor: "#FFFFFF",
      color: "#374957",
      borderRadius: "20px",
      padding: "10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      fontSize: "18px",
      lineHeight: "21px",
      marginTop: "1em",
      fontFamily: "NunitoBold",
    },
  };

  return (
    <div style={styles.appscreen}>
      <div className="mx-5 mb-5">
        {payed ? (
          <div style={styles.container}>
            <h1 style={styles.title}>{t("thanks.order-success")}</h1>
            <p style={styles.message}>{message.trim()}</p>
            <p style={styles.orderId}>
              {t("thanks.order")}: {orderId}
            </p>
            <div style={styles.feedBackContainer}>
              <img src={googleLogoBig} alt="Google Logo Big" />
              <div style={styles.feedBack}>
                <span style={styles.feedBackText1}>¿Qué te ha parecido?</span>
                <span style={styles.feedBackText2}>Déjanos una review</span>
                <StarRating
                  rating={rating}
                  onRatingChange={handleRatingChange}
                />
              </div>
            </div>
            {rating !== 0 && rating >= 4 ? (
              <div style={styles.feedbackButtonContainer}>
                <button
                  onClick={openGoogleMapsReview}
                  style={styles.feedbackButton}
                >
                  {t("thanks.rate-google")}
                  <img
                    src={google}
                    alt="Google Logo"
                    style={styles.feedbackIcon}
                  />
                </button>
              </div>
            ) : rating !== 0 && rating < 2 ? (
              <div style={styles.feedbackButtonContainer}>
                <a
                  href="https://wa.me/34644043971"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.feedbackButton}
                >
                  {t("thanks.kill-rating")}
                  <img
                    src={whatsapp}
                    alt="WhatsApp Logo"
                    style={styles.feedbackIcon}
                  />
                </a>
              </div>
            ) : rating !== 0 ? (
              <div style={styles.feedbackButtonContainer}>
                <p style={styles.feedbackButton}>
                  {t("thanks.thanks-for-rating")}
                </p>
              </div>
            ) : null}
            <h2 style={styles.retryMessage}>{t("thanks.footer-remember")}</h2>
            <p style={styles.footMessage}>
              {t("thanks.footer-message-success")}
            </p>
          </div>
        ) : (
          <div style={styles.container}>
            <h1 style={styles.title}> {t("thanks.error-on-payment")}</h1>
            <p style={styles.message}>{message.trim()}</p>
            <p style={styles.orderId}>
              {t("thanks.order")}: {orderId}
            </p>
            <h2 style={styles.retryMessage}>{t("thanks.retry-click")}</h2>
            <Button
              variant="contained"
              onClick={handleRetry}
              style={styles.retryButton}
            >
              {t("thanks.retry")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OK;
