import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Carousel from "../modules/carrusel-module";
import ServicesModule from "../modules/services-module";
import PagoModule from "../modules/pago-module";
import serviceData from "../simulatedDb/servicesTable2.json";
import ConfigModule from "../modules/config-module";
import CodigoUsuarioModule from "../modules/codigo-user-module";
import userData from "../simulatedDb/user.json";
import ChatModule from "../modules/chat-module";
import { RootState } from "../redux/store";
import imageData from "../simulatedDb/imagesTable.json";
import RoomStayModule from "../modules/roomstay-module";
import { useServices } from "../contexts/ServicesContext";

function MenuPage() {
  const { total } = useServices();
  const [lowerBottom, setLowerBottom] = useState(true);

  const [localData, setLocalData] = useState<any>();
  const images = imageData.zenitdemo[0].url; // Suponiendo imageData es parte de los datos disponibles en el alcance

  const localId = useSelector((state: RootState) => state.businessReducer.localId);

  // Actualiza lowerBottom cuando cambia total.
  useEffect(() => {
    setLowerBottom(total > 0);
  }, [total]);

  // Cargar datos del local basado en el localId obtenido del estado Redux
  useEffect(() => {
    if (localId) {
      import("../simulatedDb/familiesTable.json")
        .then((res) => {
          const data = res.default;
          if (data) {
            setLocalData(data.find((item: { _id: string }) => item._id === localId));
          } else {
            console.error(`No se encontró información para el localId: ${localId}`);
          }
        })
        .catch((error) => console.error("Error al cargar los datos:", error));
    } else {
      console.error("No hay localId aún");
    }
  }, [localId]);

  return (
    <>
      <ConfigModule showGoBack={true} />
      <div
        style={{
          ...styles.menuContainer,
          marginBottom: total > 0 ? "110px" : "30px",
        }}
      >
        <CodigoUsuarioModule userData={userData} />
        {localData && (
          <>
            <Carousel images={images} />
            <RoomStayModule />
            <ServicesModule localData={localData} serviceData={serviceData} />
          </>
        )}

        {total > 1 && <PagoModule />}
        <ChatModule forceBottom={lowerBottom} />
      </div>
    </>
  );
}

const styles: { [key: string]: CSSProperties } = {
  menuContainer: {
    color: "white",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "70px",
    gap: "20px",
  },
  chatModule: {
    width: "85%",
  },
};

export default MenuPage;
