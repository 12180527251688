import React, { CSSProperties, useState, useRef, useEffect } from "react";

interface ImageViewerProps {
  imageUrl: string;
  onClose: () => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ imageUrl, onClose }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Detiene la propagación del evento de click para evitar que llegue al contenedor subyacente
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div style={styles.overlay} onClick={onClose}>
      <div style={styles.viewer} onClick={handleClick}>
        <img style={styles.imageBig} src={imageUrl} alt="Full size" />
      </div>
    </div>
  );
};

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isClickOnCarousel, setIsClickOnCarousel] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(event.clientX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
    setIsClickOnCarousel(true);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const x = event.clientX - (carouselRef.current?.offsetLeft || 0);
    const distance = x - startX;
    carouselRef.current!.scrollLeft = scrollLeft - distance;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsClickOnCarousel(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    setIsClickOnCarousel(false);
  };

  const handleImageMouseDown = () => {
    setIsClickOnCarousel(false);
  };

  const handleImageClick = (imageUrl: string) => {
    if (!isClickOnCarousel) {
      setSelectedImage(imageUrl);
    }
  };

  const handleCloseViewer = () => {
    setSelectedImage(null);
  };

  return (
    <div
      style={styles.carousel}
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      {images.map((image: string, index: number) => (
        <img
          key={index}
          style={styles.image}
          src={image}
          alt={`Carousel ${index}`}
          draggable="false"
          onClick={() => handleImageClick(image)}
        />
      ))}
      {selectedImage && (
        <ImageViewer imageUrl={selectedImage} onClose={handleCloseViewer} />
      )}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  carousel: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    padding: "10px",
    maxWidth: "550px",
    width: "85vw",
    height: "100px",
    margin: "0 auto",
    backgroundColor: "#1E1E1ECC",
    borderRadius: "20px",
    gap: "15px"
  },
  image: {
    height: "100%",
    width: "auto",
    borderRadius: "15px",
    transition: "transform 0.5s ease",
    cursor: "pointer",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  viewer: {
    maxWidth: "90%",
    maxHeight: "90%",
  },
  imageBig: {
    width: "100%",
    height: "auto",
    borderRadius: "10px",
  },
};

export default Carousel;