import React from "react";
import { CSSProperties } from "react";

const InputNameModule: React.FC = () => {
  return (
    <div style={styles.inputContainer}>
      <input type="text" placeholder="Dinos tu nombre" style={styles.input} />
    </div>
  );
};

export default InputNameModule;

const styles: { [key: string]: CSSProperties } = {
  inputContainer: {
    display: "flex",
    padding: "10px",
    alignItems: "flex-start",
    gap: "10px",
    maxWidth: "550px",
    width: "85vw",
    borderRadius: "20px",
    background: "#060606",
  },
  input: {
    color: "#BEBEBE",
    fontFamily: "NunitoBold, sans-serif",
    background: "transparent",
    fontSize: "14px",
    flex: 1,
  },
};
