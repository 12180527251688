import React, { useState, useEffect, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom'; 

interface CriptosModuleProps {
  isEnabled: boolean;
}

const CriptosModule: React.FC<CriptosModuleProps> = ({ isEnabled }) => {

  const navigate = useNavigate();
  const [CriptosIconPath, setCriptosIconPath] = useState('');

  const handleButtonClick = () => {
    if (!isEnabled) {
      console.log("Checkbox no marcado, acción bloqueada.");
      return; // Previene la acción si el checkbox no está marcado
    }
    // Procede con la acción de navegación si el checkbox está marcado
    navigate("/ruta-de-destino");
  };

  useEffect(() => {
    const loadPaymentIcons = async () => {
      try {
        const iconsConfig = await import('../simulatedDb/paymentIconsDb.json');
        setCriptosIconPath(iconsConfig.criptos);
      } catch (error) {
        console.error("Error al cargar el archivo de configuración de iconos de pago:", error);
      }
    };

    loadPaymentIcons();
  }, []);

    return (
        <button onClick={handleButtonClick} style={styles.button}>
         CRIPTOS {CriptosIconPath && <img src={CriptosIconPath} style={styles.icon} alt="Criptos" />}
        </button>
   
    );
  };

export default CriptosModule;

const styles: Record<string, CSSProperties> = {
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    padding: "12px 20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#FFF",
    border: "none",
    cursor: "pointer",
    width: "100%",
    fontSize: "16px",
    fontFamily: "NunitoBold",
    color: "black",
    gap: "-10px",
    position: 'relative',
  },
  icon: {
    marginLeft: '10px',
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
    top: '-1px',
  },
};