import React, { CSSProperties, useState } from "react";
import CodigoUsuarioModule from "../modules/codigo-user-module"; // Asegúrate de que la ruta del archivo sea correcta
import ConfigModule from "../modules/config-module"; // Asegúrate de que la ruta del archivo sea correcta
import userData from "../simulatedDb/user.json"; // Asegúrate de que la ruta del archivo sea correcta
import PaymentFormModule from "../modules/formularioPago-module";
import ApplePayModule from "../modules/applePay-module";
import GooglePayModule from "../modules/googlePay-module";
import PagoTarjetaModule from "../modules/pagoTarjeta-module";
import BizumModule from "../modules/bizum-module";
import CriptosModule from "../modules/criptos-module";

function PagoPage() {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  return (
    <>
      <div style={styles.paymentContainer}>
        <div style={styles.configModule}>
          <ConfigModule showGoBack={true} />
        </div>
        <div style={styles.codigoModule}>
          <CodigoUsuarioModule userData={userData} />
        </div>

        <div className="payment-form">
          <PaymentFormModule
            userName={userData.name}
            onCheckboxChange={setIsCheckboxChecked} // Pasas la función para modificar el estado
          />
        </div>

        <div style={styles.buttons}>
          <ApplePayModule isEnabled={isCheckboxChecked} />
          <GooglePayModule isEnabled={isCheckboxChecked} />
          <BizumModule isEnabled={isCheckboxChecked} />
          <PagoTarjetaModule isEnabled={isCheckboxChecked} />
          <CriptosModule isEnabled={isCheckboxChecked} />
        </div>
      </div>
    </>
  );
}

export default PagoPage;

const styles: { [key: string]: CSSProperties } = {
  paymentContainer: {
    color: "white",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  configModule: {
    color: "#121212",
  },
  codigoModule: {
    marginTop: "70px",
    marginBottom: "20px",
  },
  buttons: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "550px",
    width: "90%",
  },
};
