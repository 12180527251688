// PagoEsperaModule.tsx
import React, { useState, useEffect } from 'react';
import { useServices } from '../contexts/ServicesContext';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';




const PagoEsperaModule: React.FC = () => {
  const { addedServices, total } = useServices();
  const [showDivideOptions, setShowDivideOptions] = useState<{ [id: string]: boolean }>({});
  const [filteredServices, setFilteredServices] = useState<any[]>([]);

  useEffect(() => {


    const newFilteredServices = Object.keys(addedServices).reduce<any[]>((acc, key) => {
      const service: any = addedServices[key]; 

      const validOptions = service.options.filter((opt: any) => opt.value === true);
      const validOptionsAdd = service.optionsAdd?.filter((optAdd: any) => optAdd.value ===true) || [];

      if (validOptions.length > 0 || validOptionsAdd.length > 0) {
        acc.push({
          _id: service._id || key,
          name: service.name,
          description: service.description,
          paymentType: service.paymentType,
          options: validOptions,
          optionsAdd: validOptionsAdd
        });
      }
      return acc;
    }, []);
    console.log("servicios fltrados: ", newFilteredServices);
    setFilteredServices(newFilteredServices);
  }, [addedServices]);

  const toggleDivideOptions = (id: string) => {
    setShowDivideOptions(prev => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div style={styles.wrapListado} className="backdrop-filter-blur">
      <h2 style={styles.tituloPorPagar}>Por Pagar</h2>
      <ul style={styles.listaServicios}>
        {filteredServices.map((clase: any) => (
          <li key={clase._id} style={styles.servicio}>
            <div className="servicio-info">
              <h3>{clase.name}</h3>
              <p style={styles.servicioPaymentType}>{clase.paymentType}</p>
            </div>
            <div style={styles.servicioPrecio}>
              <button
                style={{ ...styles.servicioDividir, ...(showDivideOptions[clase._id] ? styles.servicioDividirActivo : null) }}
                onClick={() => toggleDivideOptions(clase._id)}
              >
                x
              </button>
              <div className={`opciones-dividir ${showDivideOptions[clase._id] ? "opciones-dividir-activo" : ""}`}>
                <button style={styles.opcion}>1/2</button>
                <button style={styles.opcion}>1/3</button>
                <button style={styles.opcion}>%</button>
              </div>
              <span style={styles.precioFinal}>{`${total} €`}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PagoEsperaModule;

const styles: { [key: string]: React.CSSProperties } = {
  button: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 20px",
    borderRadius: "8px",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#FFF",
    border: "none",
    cursor: "pointer",
    width: "100%",
    fontSize: "16px",
    fontFamily: "NunitoBold",
    color: "black"
  },
  resumenPagos: {
    fontFamily: 'Nunito, sans-serif',
    color: 'white',
    padding: '20px',
    margin: 'auto',
    width: '80%',
    maxWidth: '60%',
    borderRadius: '20px',
  },
  wrapListado: {
    marginTop: '20px',
    background: 'rgba(30, 30, 30, 0.80)',
    borderRadius: '10px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    color: '#fff',
    maxWidth: '550px',
    width: '85vw',
    overflow: 'hidden',
  },
  tituloPorPagar: {
    fontSize: '1.5em',
    marginBottom: '20px',
    alignSelf: 'right',
    color: 'white',
  },
  resumenPagosH2: {
    fontSize: '1.5em',
    marginBottom: '20px',
    display: 'flex',
  },
  servicio: {
    backgroundColor: '#060606',
    borderRadius: '10px',
    marginBottom: '2%',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    color: '#fff',
    height: '80%',
    maxWidth: '520px',
    width: '78vw',
  },
  servicioInfoH3: {
    margin: '0',
    fontSize: '1.2em',
  },
  servicioPaymentType: {
    fontSize: '1em',
    position: 'relative',
    top: '20px',
  },
  servicioPrecio: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'relative',
    overflow: 'visible',
  },
  precioFinal: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: 'white',
    marginTop: '10px',
  },
  listaServicios: {
    alignItems: 'center',
  },
  servicioDividir: {
    backgroundColor: '#1F1F1F',
    color: 'white',
    border: 'none',
    padding: '7.5px 15px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginBottom: '15px',
    transition: 'transform 0.3s ease',
  },
  ul: {
    display: 'block',
    listStyleType: 'disc',
    marginBlockStart: '0em',
    marginBlockEnd: '0em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    paddingInlineStart: '0px',
  },
  opcionesDividir: {
    display: 'none',
    position: 'absolute',
    top: '2px',
    left: '-100%',
    whiteSpace: 'nowrap',
    zIndex: 10,
    transform: 'translateX(-70%)',
  },
  opcion: {
    backgroundColor: '#1F1F1F',
    color: 'white',
    border: 'none',
    marginLeft: '5px',
    padding: '5px 10px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  servicioDividirActivo: {
    zIndex: 2,
  },
  opcionesDividirActivo: {
    display: 'flex',
  },
};

// Estilos para media query
const mediaStyles: { [key: string]: React.CSSProperties } = {
  resumenPagosMedia: {
    maxWidth: 'none',
    padding: '16px',
    transform: 'none',
    position: 'static',
    width: '80%',
    margin: 'auto',
  },
  servicioMedia: {
    padding: '10px',
  },
  servicioDividirMedia: {
    textAlign: 'center',
    alignItems: 'center',
    padding: '7.5px 15px',
    fontSize: '0.8em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    transition: 'transform 0.3s ease',
  },
  opcionesDividirMedia: {
    marginLeft: '15px',
  },
  opcionMedia: {
    marginBottom: '5px',
    transform: 'translateX(-70%)',
  },
};

// Merge de estilos
const mergedStyles = { ...styles, '@media (max-width: 768px)': mediaStyles };