import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define el tipo para el payload de la acción setInfo
interface SetInfoPayload {
  localId: string;
}

interface BusinessState {
  localId: string;
  banner: string;
}

const initialState: BusinessState = {
  localId: sessionStorage.getItem("localId") ?? "",
  banner: "",
};

export const businessSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setInfo: (state, action: PayloadAction<SetInfoPayload>) => {
      state.localId = action.payload.localId;
    },
  },
});

export const { setInfo } = businessSlice.actions;

export default businessSlice.reducer;
