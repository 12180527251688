import React, { useState, useRef } from "react";
import flags from "../simulatedDb/banderasDb.json";
import users from "../simulatedDb/logintypesDb.json";

type FlagKey = keyof typeof flags;
type UserKey = keyof typeof users;

interface ConfigModuleProps {
  showGoBack: boolean;
}

const ConfigModule: React.FC<ConfigModuleProps> = ({ showGoBack }) => {
  const [selectedFlag, setSelectedFlag] = useState<FlagKey>("es");
  const [isOpenFlag, setIsOpenFlag] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserKey>("google");
  const [isOpenUser, setIsOpenUser] = useState(false);
  const dropdownUserRef = useRef<HTMLDivElement>(null);

  const handleGoBack = () => {
    window.history.back();
  };

  const toggleDropdownFlag = () => {
    setIsOpenFlag(!isOpenFlag);
    if (isOpenUser) setIsOpenUser(false);
  };

  const handleSelectFlag = (
    key: FlagKey,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    setSelectedFlag(key);
    setIsOpenFlag(false);
  };

  const toggleDropdownUser = () => {
    setIsOpenUser(!isOpenUser);
    if (isOpenFlag) setIsOpenFlag(false);
  };

  const handleSelectUser = (
    key: UserKey,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    setSelectedUser(key);
    setIsOpenUser(false);
  };
  return (
    <div
      style={
        showGoBack
          ? {
              ...styles.configGeneralContainer,
              ...styles.configGeneralContainerWithGoBack,
            }
          : {
              ...styles.configGeneralContainer,
              ...styles.configGeneralContainerWithoutGoBack,
            }
      }
    >
      {/* Botón de volver */}
      {showGoBack && (
        <div
          className={`backdrop-filter-blur`}
          style={styles.goBackContainer}
          onClick={handleGoBack}
        >
          <img
            src="/icons/fi-rr-angle-small-left.svg"
            alt="Volver"
            style={styles.goBackIcon}
          />
          <div style={styles.goBackText}>VOLVER</div>
        </div>
      )}

      <div className={`backdrop-filter-blur`} style={styles.configContainer}>
        <div style={styles.selectionContainer}>
          {/* Bandera */}
          <div style={styles.flagContainer} onClick={toggleDropdownFlag}>
            <img
              src={flags[selectedFlag].url}
              alt="Selected flag"
              style={styles.flag}
            />
            <div style={styles.flagIconContainer}>
              <img src="/arrow.icon.png" alt="arrow" style={styles.flagIcon} />
            </div>
          </div>

          {/* Icono */}
          <div
            ref={dropdownUserRef}
            style={{
              ...styles.userIconContainer,
              ...(isOpenUser ? styles.userIconOpen : {}),
            }} // Combina estilos con isOpenUser
            onClick={toggleDropdownUser}
          >
            <div style={styles.userIconContainer}>
              <img src="/user.icon.jpeg" alt="User" style={styles.user} />
            </div>
          </div>
        </div>

        {isOpenFlag && (
          <div style={styles.flagDropdownList}>
            {Object.entries(flags).map(([key, { url, name }]) => (
              <div
                key={key}
                style={styles.flagDropdownItem}
                onClick={(event) => handleSelectFlag(key as FlagKey, event)}
              >
                <span>{name}</span>
                <img
                  src={url}
                  alt={`${name} flag`}
                  style={styles.flagDropdownItemImage}
                />
              </div>
            ))}
          </div>
        )}

        {isOpenUser && (
          <div style={styles.userDropdownList}>
            {Object.entries(users).map(([key, { url }]) => (
              <div
                key={key}
                style={styles.userDropdownItem}
                onClick={(event) => handleSelectUser(key as UserKey, event)}
              >
                <img
                  src={url}
                  alt={`${key} login`}
                  style={styles.userDropdownItemImage}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigModule;

const styles: { [key: string]: React.CSSProperties } = {
  configGeneralContainer: {
    position: "fixed",
    top: "10px",
    left: "50%",
    transform: "translate(-50%)",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: "20px",
    width: "95vw",
    zIndex: 10,
    fontFamily: "Nunito, sans-serif",
  },
  configGeneralContainerWithGoBack: {
    justifyContent: "space-between",
    maxWidth: "600px",
  },
  configGeneralContainerWithoutGoBack: {
    justifyContent: "flex-end",
  },
  goBackContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    gap: "10px",
    marginTop: "5px",
    padding: "5px 10px",
    borderRadius: "20px",
    background: "rgba(30, 30, 30, 0.6)",
  },
  goBackIcon: {
    fill: "#ffffff",
    width: "18px",
    height: "auto",
  },
  goBackText: {
    color: "#fff",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  configContainer: {
    color: "white",
    padding: "5px 10px",
    borderRadius: "20px",
    background: "rgba(30, 30, 30, 0.6)",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  isOpenFlag: {
    padding: "5px 10px 10px 10px",
  },
  isOpenUser: {
    padding: "5px 10px 10px 10px",
  },
  selectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    height: "auto",
  },
  flagContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
    height: "22px",
    overflow: "hidden",
  },
  flag: {
    height: "100%",
    width: "auto",
    objectFit: "cover",
  },
  flagIconContainer: {
    backgroundColor: "#e5e5e5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "-4px",
    paddingLeft: "2px",
    paddingRight: "2px"
  },
  flagIcon: {
    width: "8px",
  },
  user: {
    borderRadius: "50%",
    height: "30px",
  },
  flagDropdownList: {
    position: "relative",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    zIndex: 3,
    gap: "20px",
  },
  flagDropdownItem: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    color: "white",
    alignItems: "center",
    justifyContent: 'space-between',
  },
  flagDropdownItemImage: {
    width: "30px",
    borderRadius: "10px",
  },
  flagDropdownItemSpan: {
    flexGrow: 1,
    fontSize: "12px"
  },
  userDropdownList: {
    position: "relative",
    display: "flex",
    width: "130px",
    flexDirection: "row",
    zIndex: 3,
    justifyContent: "space-between",
    gap: "5px",
  },
  userDropdownItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px",
  },
  userDropdownItemImage: {
    width: "18px",
    height: "auto",
  },
  userIconContainer: {
    display: "flex",
    alignItems: "center",
  },
};
