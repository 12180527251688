import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSpring, animated } from '@react-spring/web';
import axios from 'axios';
import { useServices } from "../contexts/ServicesContext";

interface ChatModuleProps {
  forceBottom?: boolean;
  autoWrite?: boolean;
}

const ChatModule: React.FC<ChatModuleProps> = ({ forceBottom, autoWrite = true }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { messages, setMessages, userID, setUserID } = useServices();
  const [inputText, setInputText] = useState<any>('');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  }, [inputText]);

  useEffect(() => {
    if (autoWrite) {
      const handleKeyDown = (e: KeyboardEvent) => {
        const isAlphaNumericOrSymbol = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/.test(e.key);
        const isControlKey = e.ctrlKey || e.metaKey || e.altKey;

        if (isAlphaNumericOrSymbol && inputRef.current && !isControlKey) {
          inputRef.current.focus();
        }
      };

      const handleBeforeInput = (e: Event) => {
        const inputEvent = e as InputEvent;
        if (inputEvent.inputType === 'insertFromPaste') {
          e.preventDefault();
          const pastedText = inputEvent.data || '';
          setInputText((prevInputText: any) => prevInputText + pastedText);
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      inputRef.current?.addEventListener('beforeinput', handleBeforeInput);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        inputRef.current?.removeEventListener('beforeinput', handleBeforeInput);
      };
    }
  }, [autoWrite]);

  const sendMessageToBackend = async (text: string) => {
    console.log('Enviando mensaje al backend:', text);
    try {
      const baseURL = process.env.BACKEND_URL || "https://be-chat-server.vercel.app";
      const endpoint = userID === '-1' ? `${baseURL}/start` : `${baseURL}/pregunta`;

      console.log('Endpoint seleccionado:', endpoint);
      const { data } = await axios.post(endpoint, {
        pregunta: text,
        userID: userID,
      });

      console.log('Respuesta recibida del backend:', data);
      if (data.userID && userID === '-1') {
        console.log('Actualizando userID:', data.userID);
        setUserID(data.userID);
        localStorage.setItem('userID', data.userID);
      }

      const botMessage: any = { text: data.respuesta, type: 'message-bot' };
      setMessages((prevMessages: any) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error al enviar mensaje al backend:', error);
    }
  };

  const handleSendMessage = () => {
    console.log('Manejando el envío del mensaje');
    if (inputText.trim() !== '') {
      const userMessage: any = { text: inputText, type: 'message-client' };
      setMessages((prevMessages: any) => [...prevMessages, userMessage]);

      sendMessageToBackend(inputText);
      setInputText('');
      console.log('Mensaje enviado y campo de texto limpiado');
    }
    if (location.pathname !== '/chat') {
      navigate('/chat');
    }
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const [animatedStyle, setAnimatedStyle] = useSpring(() => ({
    bottom: '10px',
    config: { tension: 800, friction: 30 }
  }));

  useEffect(() => {
    setAnimatedStyle({ bottom: forceBottom ? '95px' : '10px' });
  }, [forceBottom, setAnimatedStyle]);

  return (
    <animated.div style={{ ...styles.chatModuleContainer, ...animatedStyle }}>
      <textarea
        ref={inputRef}
        value={inputText}
        className="input"
        style={styles.input}
        onChange={(e) => setInputText(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Escribe tu mensaje..."
      />
      <button onClick={handleSendMessage} style={styles.button}>
        <img src="/icons/fi-rr-paper-plane.svg" alt="Enviar" style={styles.icon} />
      </button>
    </animated.div>
  );
};

export default ChatModule;

const styles: { [key: string]: React.CSSProperties } = {
  chatModuleContainer: {
    position: "fixed",
    left: "50%",
    transform: "translate(-50%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "row",
    width: "90vw",
    padding: "5px",
    maxWidth: "600px",
    zIndex: 10,
    color: "white",
    fontFamily: "Nunito, sans-serif",
    borderRadius: "20px",
    background: "#060606",
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 12px 24px 0px rgba(0, 0, 0, 0.12)",
  },
  input: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    color: 'white',
    fontFamily: 'Nunito, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    marginRight: '10px',
    padding: '5px 10px',
    outline: 'none',
    resize: 'vertical',
    overflowY: 'auto',
    maxHeight: '300px',
    minHeight: 'auto',
    height: 'auto',
  },
  button: {
    backgroundColor: '#4caf50',
    border: 'none',
    padding: '10px',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '7px'
  },
  icon: {
    width: '20px',
    height: '20px',
  },
};
