import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useServices } from "../contexts/ServicesContext";
import { ReactComponent as LogoIcon } from '../icons/bechat_logo_white.svg';


const MesagesModule: React.FC = () => {
    const { messages, setMessages, userID, setUserID } = useServices();

    const chatRef = useRef<HTMLDivElement>(null);
    const [loadedMessagesCount, setLoadedMessagesCount] = useState(15);
    const [screenSizeDesktop, setScreenSizeDesktop] = useState(window.innerWidth > 768);
    const [loadingMessages, setLoadingMessages] = useState(false);

    const handleClearHistory = () => {
        setMessages([]);
        setUserID('-1');
    };

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = 0;
        }
    }, [messages]);

    useEffect(() => {
        const handleResize = () => {
            setScreenSizeDesktop(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (chatRef.current) {
                if (chatRef.current.scrollTop === 0 && loadedMessagesCount < messages.length) {
                    const remainingMessages = messages.length - loadedMessagesCount;
                    const countToLoad = remainingMessages < 10 ? remainingMessages : 10;
                    const currentScrollHeight = chatRef.current.scrollHeight;

                    setLoadedMessagesCount(loadedMessagesCount + countToLoad);

                    queueMicrotask(() => {
                        if (chatRef.current) {
                            const newScrollHeight = chatRef.current.scrollHeight;
                            chatRef.current.scrollTop = newScrollHeight - currentScrollHeight + 50;
                        }
                    });
                }
            }
        };
        const currentChatRef = chatRef.current;
        currentChatRef?.addEventListener('scroll', handleScroll);
        return () => {
            currentChatRef?.removeEventListener('scroll', handleScroll);
        };
    }, [loadedMessagesCount, messages]);

    useEffect(() => {
        const recuperarConversacionAnterior = async () => {
            if (userID && userID !== '-1') {
                try {
                    const baseURL = process.env.REACT_APP_BACKEND_URL;
                    const { data } = await axios.post(`${baseURL}/recuperarConversacionAnterior`, { userID: userID });

                    const mensajesTransformados = data.map((mensaje: any) => ({
                        text: mensaje.content,
                        type: mensaje.sender === 'client' ? 'message-client' : 'message-bot',
                    }));
                    setMessages(mensajesTransformados);
                } catch (error) {
                    console.error('Error al recuperar la conversación anterior:', error);
                }
            }
        };

        recuperarConversacionAnterior();
    }, [userID]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, []);


    return (
        <div ref={chatRef} style={styles.messageContainer}>
            <div style={styles.chatBackground}>
                <LogoIcon style={styles.chatBackgroundLogo}/>
            </div>
            {messages.slice(Math.max(messages.length - loadedMessagesCount, 0)).map((message: any, index: any) => {
                const messageStyle = message.type === 'message-client' ? styles.messageClient : styles.messageBot;
                return (
                    <div className="backdrop-filter-blur" key={index} style={messageStyle}>
                        <pre style={styles.text}>{message.text}</pre>
                    </div>
                );
            })}
            <button onClick={handleClearHistory} style={styles.clearButton}>Limpiar historial</button>
        </div>
    );
};


export default MesagesModule;

const styles: { [key: string]: React.CSSProperties } = {
    messageContainer: {
        display: 'flex',
        color: 'white',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'start',
        maxWidth: "550px",
        width: "85vw",
        marginTop: "70px",
        paddingBottom: "80px",
        gap: "10px",
        overflowY: 'visible'
    },
    messageClient: {
        display: 'flex',
        backgroundColor: 'rgba(45, 45, 45, 0.8)',
        alignSelf: 'flex-end',
        padding: '14px',
        marginBottom: '8px',
        borderRadius: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word'
    },
    messageBot: {
        display: 'flex',
        backgroundColor: 'rgba(30, 30, 30, 0.8)',
        alignSelf: 'flex-start',
        padding: '14px',
        marginBottom: '8px',
        borderRadius: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word'
    },
    text: {
        fontSize: '16px',
        fontFamily: 'Nunito',
        whiteSpace: 'pre-wrap'
    },
    chatBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0
    },
    chatBackgroundLogo: {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200px',
        height: 'auto',
        opacity: 0.2,
        zIndex: 0
    },
    clearButton: {
        position: 'fixed',
        bottom: '85px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        padding: '4px 8px',
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        color: '#000000',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px'
    },
};