// Prepago.tsx
import React, { CSSProperties, useState } from "react";
import ResumenPagos from "../modules/pagoEspera-module"; // Ajusta la ruta según tu estructura
import CodigoUsuarioModule from "../modules/codigo-user-module"; // Asegúrate de que la ruta del archivo sea correcta
import userData from "../simulatedDb/user.json"; // Asegúrate de que la ruta del archivo sea correcta
import PagoModule from "../modules/pago-module";
import ConfigModule from "../modules/config-module"; // Asegúrate de que la ruta del archivo sea correcta
import InputNameModule from "../modules/inputName-module";
import { useLocation } from "react-router-dom";

const Prepago: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const totalStr = searchParams.get("total"); // Esto puede ser 'string' o 'null'

  // Convertir a número con un valor predeterminado de '0' si 'totalStr' es 'null'
  const total = totalStr ? parseFloat(totalStr) : 0;
  return (
    <>
      <div style={styles.prepagoContainer}>
        <div>
          <ConfigModule showGoBack={true} />
        </div>
        <div style={styles.codigoModule}>
          <CodigoUsuarioModule userData={userData} />
        </div>
        <div>
          <InputNameModule />
        </div>
        <ResumenPagos />
        <div>
          <PagoModule  />
        </div>
      </div>
    </>
  );
};

export default Prepago;

const styles: { [key: string]: CSSProperties } = {
  prepagoContainer: {
    color: "white",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px"
  },
  codigoModule: {
    marginTop: "60px",
  },
};
